import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { retry } from 'rxjs/operators';
import { Storage } from '@ionic/storage-angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  authState = new BehaviorSubject(false);
  private _storage: Storage | null = null;
  apiHost = 'https://bootsapp-wp.quantumfrog-aws.de/wp-json';
  token: string;
  
  constructor(private router: Router,
    private storage: Storage,
    private platform: Platform,
    private httpClient: HttpClient)
  {
    this.platform.ready().then(() => {
      this.init().then(() => this.ifLoggedIn());
    });
  }

  async init() {
    const storage = await this.storage.create();
    this._storage = storage;
  }

  // Check if user is logged in
  ifLoggedIn() {
    // Check if storage is available
    if (this._storage == null) {
      this.authState.next(false);
    }
    this._storage.get('USER_INFO').then((response) => {
      if (response) {
        this.token = response.token;
        this.authState.next(true);
      }
    });
  }

  // Check if user is authenticated
  isAuthenticated() {
    console.log('isAuthenticated: ' + this.authState.value);
    return this.authState.value;
  }

  // Login a user in Wordpress
  login(username:String, password:String): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.httpClient.post<any>(
      this.apiHost + '/jwt-auth/v1/token',
      {
        username: username,
        password: password
      },
      httpOptions
    ).pipe(retry(2));
  }

  // Login as guest in Wordpress
  loginAsGuest(): Observable<any> {
    let httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.httpClient.post<any>(
      this.apiHost + '/jwt-auth/v1/token',
      {
        username: 'guest',
        password: 'guest'
      },
      httpOptions
    ).pipe(retry(2));
  }

  // Set the token in local storage
  async setToken(token: string) {
    this._storage.set('USER_INFO', { token: token }).then(() => {
      this.token = token;
      this.authState.next(true);
    }).catch(() => {
      this.token = "";
      this.authState.next(false);
    });
  }

  getToken() {
    return this.token;
  }

  // Logout a user in Wordpress
  logout() {
    this._storage.remove('USER_INFO').then(() => {
      this.token = "";
      this.authState.next(false);
    });
  }

  // Check Response of Wordpress Login
  checkResponse(response: any) {
    if (response.success) {
      return true;
    } else {
      return false;
    }
  }

}
