import { Injectable } from '@angular/core';
import { SQLite, SQLiteObject } from '@awesome-cordova-plugins/sqlite/ngx';

@Injectable({
  providedIn: 'root'
})
export class LocalsqlService {

  constructor(private sqlite: SQLite) { }

  // Return Promise of SQLDB Creation
  createSampleDB(){
    return this.sqlite.create({
      name: 'sample.db',
      location: 'default'
    });
  }

  // Return Promise of sample Statement
  executesSampleStatement(db: SQLiteObject){
    if (!db){
      console.log('Cordova Object not found');
      return null;
    }
    return db.executeSql("CREATE TABLE 'version' ('version_number' INT(11) NOT NULL DEFAULT '0'",[]);
  }
}
